exports.components = {
  "component---plugins-petlab-upsell-devtools-templates-list-js": () => import("./../../../plugins/petlab-upsell-devtools/templates/list.js" /* webpackChunkName: "component---plugins-petlab-upsell-devtools-templates-list-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-banners-editing-js": () => import("./../../../src/pages/banners-editing.js" /* webpackChunkName: "component---src-pages-banners-editing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-symbol-editing-js": () => import("./../../../src/pages/symbol-editing.js" /* webpackChunkName: "component---src-pages-symbol-editing-js" */),
  "component---src-pages-upsell-editing-js": () => import("./../../../src/pages/upsell-editing.js" /* webpackChunkName: "component---src-pages-upsell-editing-js" */),
  "component---src-pages-upsell-preview-js": () => import("./../../../src/pages/upsell-preview.js" /* webpackChunkName: "component---src-pages-upsell-preview-js" */),
  "component---src-pages-upsell-symbol-editing-js": () => import("./../../../src/pages/upsell-symbol-editing.js" /* webpackChunkName: "component---src-pages-upsell-symbol-editing-js" */),
  "component---src-templates-upsell-jsx": () => import("./../../../src/templates/Upsell.jsx" /* webpackChunkName: "component---src-templates-upsell-jsx" */)
}

