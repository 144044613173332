import React from "react";
import "../builder-settings";

const PageLayout = ({ children }) => {
  return <div>{children}</div>;
};

const Component = props => {
  return <PageLayout {...props} />;
};

export default Component;
