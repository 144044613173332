import { Builder, withChildren } from "@builder.io/react";
import CarouselContainer from "./CarouselContainer";

Builder.registerComponent(withChildren(CarouselContainer), {
  name: "Carousel Container",
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  inputs: [
    {
      name: "settings",
      type: "object",
      advanced: true,
      defaultValue: {
        showStatus: false,
        showImgArray: false,
        thumbWidth: 80,
        swipeScrollTolerance: 5,
        autoPlay: false,
        centerMode: false,
        emulateTouch: false,
        infiniteLoop: false,
        interval: 3000,
        preventMovementUntilSwipeScrollTolerance: false,
        stopOnHover: false,
        swipeable: true,
        useKeyboardArrows: false,
        selectedItem: 0,
        showArrows: true,
        showIndicators: true,
        thumbsPosition: "bottom",
        showThumbs: true,
        ariaLabel: "",
        axis: "horizontal",
        dynamicHeight: false,
        transitionTime: 500
      },
      subFields: [
        {
          name: "ariaLabel",
          type: "string",
          defaultValue: ""
        },
        {
          name: "axis",
          type: "string",
          defaultValue: "horizontal",
          enum: ["horizontal", "vertical"]
        },
        {
          name: "thumbsPosition",
          type: "string",
          enum: ["top", "bottom", "left", "right"],
          defaultValue: "bottom"
        },
        {
          name: "autoPlay",
          type: "boolean",
          defaultValue: false
        },
        {
          name: "centerMode",
          type: "boolean",
          defaultValue: false
        },
        {
          name: "centerSlidePercentage",
          type: "number",
          defaultValue: 80
        },
        {
          name: "dynamicHeight",
          type: "boolean",
          defaultValue: false
        },
        {
          name: "emulateTouch",
          type: "boolean",
          defaultValue: true
        },
        {
          name: "infiniteLoop",
          type: "boolean",
          defaultValue: false
        },
        {
          name: "interval",
          type: "number",
          defaultValue: 3000
        },
        {
          name: "preventMovementUntilSwipeScrollTolerance",
          type: "boolean",
          defaultValue: false
        },

        {
          name: "selectedItem",
          type: "number",
          defaultValue: 0
        },
        {
          name: "showArrows",
          type: "boolean",
          defaultValue: true
        },
        {
          name: "showStatus",
          type: "boolean",
          defaultValue: true
        },
        {
          name: "showIndicators",
          type: "boolean",
          defaultValue: true
        },
        {
          name: "showThumbs",
          type: "boolean",
          defaultValue: true
        },
        {
          name: "stopOnHover",
          type: "boolean",
          defaultValue: true
        },
        {
          name: "swipeable",
          type: "boolean",
          defaultValue: true
        },
        {
          name: "swipeScrollTolerance",
          type: "number",
          defaultValue: 5
        },
        {
          name: "thumbWidth",
          type: "number",
          defaultValue: 80
        },
        {
          name: "transitionTime",
          type: "number",
          defaultValue: 500
        },
        {
          name: "useKeyboardArrows",
          type: "boolean",
          defaultValue: false
        },
        {
          name: "addItemGap",
          type: "boolean",
          defaultValue: false
        },
        {
          name: "itemGapSize",
          type: "number",
          defaultValue: 16
        },
        {
          name: "arrowSettings",
          type: "object",
          subFields: [
            {
              name: "arrowColor",
              type: "color"
            },

            {
              name: "arrowOpacity",
              type: "string",
              enum: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
              defaultValue: 100
            },
            {
              name: "arrowHoverOpacity",
              type: "string",
              enum: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
              defaultValue: 100
            },
            {
              name: "arrowBgColor",
              type: "color"
            },
            {
              name: "arrowHoverBgColor",
              type: "color"
            },
            {
              name: "arrowStyle",
              type: "string",
              enum: ["default", "<LTS - GTS>", "arrow3"],
              defaultValue: "default"
            }
          ]
        },
        {
          name: "dotSettings",
          type: "object",
          subFields: [
            {
              name: "dotColor",
              type: "color"
            },
            {
              name: "dotColorInactive",
              type: "color"
            },
            {
              name: "dotShadow",
              type: "boolean"
            }
          ]
        }
      ]
    }
  ]
});
