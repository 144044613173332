import { Builder, withChildren } from "@builder.io/react";
import loadable from "@loadable/component";

const Timer = loadable(() => import("./Timer"));

const TimerConfig = {
  name: "Timer",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  canHaveChildren: true,
  // static: true,
  // noWrap: true,
  // hideFromInsertMenu: true,
  inputs: [
    {
      name: "display",
      type: "string",
      enum: [
        "default",
        "minutes",
        "seconds",
        "default-with-hours",
        "default-with-days"
      ],
      defaultValue: "default"
    },
    {
      name: "initialDays",
      type: "number",
      defaultValue: "00",
      advanced: true
    },
    {
      name: "initialHours",
      type: "number",
      defaultValue: "00",
      advanced: true
    },
    {
      name: "initialMinutes",
      type: "number",
      defaultValue: "00",
      advanced: true
    },
    {
      name: "initialSeconds",
      type: "number",
      defaultValue: "00",
      advanced: true
    }
  ]
};

Builder.registerComponent(withChildren(Timer), TimerConfig);
