import { Builder, withChildren } from "@builder.io/react";
import loadable from "@loadable/component";

const TrustpilotPlugin = loadable(() => import("./TrustpilotPlugin"));

const TrustpilotPluginConfig = {
  name: "Trustpilot Plugin",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  canHaveChildren: true,
  // static: true,
  // noWrap: true,
  // hideFromInsertMenu: true,
  inputs: [
    {
      name: "templateId",
      type: "string",
      defaultValue: "539ad60defb9600b94d7df2c"
    },
    {
      name: "businessUnitId",
      type: "string",
      defaultValue: "5c9df8263ff4c4000185ea05"
    },
    {
      name: "height",
      type: "string",
      defaultValue: "500px"
    },
    {
      name: "width",
      type: "string",
      defaultValue: "100%"
    },
    {
      name: "theme",
      type: "string",
      enum: ["light", "dark"],
      defaultValue: "light"
    },
    {
      name: "tags",
      type: "string",
      defaultValue: "PBC"
    },
    {
      name: "stars",
      type: "string",
      defaultValue: "4,5"
    }
  ]
};

Builder.registerComponent(
  withChildren(TrustpilotPlugin),
  TrustpilotPluginConfig
);
