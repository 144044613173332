import styled from "styled-components";
import { rgbaToHex } from "../../utils/color";

export const Container = styled.div`
  & .carousel .slide img {
    max-width: 390px;
  }
  & .carousel .thumbs {
    padding: 0;
    text-align: center;
  }
  & .carousel .thumb {
    margin-right: 0;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    cursor: pointer;
  }
  & .carousel .thumb:hover {
    border: 3px solid #d26546;
  }
  & .carousel .thumb.selected {
    border: 3px solid #d26546;
    opacity: 1;
  }
  & .carousel .thumbs-wrapper {
    margin: 0;
  }

  & .carousel .control-arrow {
    height: 40px;
    margin: auto 0;
  }

  & .carousel .control-dots {
    margin: 0;
  }

  ${({ dotSettings }) => `
    .carousel .control-dots .dot {
      background: ${dotSettings?.dotColorInactive ?? `#fff`};
      box-shadow: ${
        dotSettings?.dotShadow ? `rgba(0, 0, 0, 0.9) 1px 1px 2px` : `none`
      };
    }

    & .carousel .control-dots .dot.selected, 
    & .carousel .control-dots .dot:hover {
      background: ${dotSettings?.dotColor ?? `#fff`};
    }
  `}
  ${({ dotSettings }) => {
    switch (dotSettings?.dotStyle) {
      case "pdp2024":
        return `
        .carousel .control-dots .dot {
          margin: 0 3px;
          width: 6px;
          height: 6px;
          transition: all 0.5s ease-in-out 0s;
        }
        & .carousel .control-dots .dot.selected {
          width: 24px;
          height: 6px;
          border-radius: 8px;
        }
        `;
      case "default":
      default:
        return ``;
    }
  }}

  ${({ arrowSettings }) => {
    if (arrowSettings) {
      const {
        arrowBgColor,
        arrowColor,
        arrowHoverBgColor,
        arrowHoverOpacity,
        arrowOpacity
      } = arrowSettings;

      return `
        & .carousel .control-prev.control-arrow:before {
            border-right: 8px solid ${arrowColor ?? "#FFF"};
        }
        & .carousel .control-next.control-arrow:before {
            border-left: 8px solid ${arrowColor ?? "#FFF"};
        }
        & .carousel.carousel-slider .control-arrow:hover {
            background-color: ${arrowHoverBgColor ?? "rgba(0, 0, 0, 0.2)"};
            opacity: ${(arrowHoverOpacity ?? 40) / 100}
        }
        & .carousel.carousel-slider .control-arrow {
            background-color: ${arrowBgColor ?? "rgba(0, 0, 0, 0.2)"};
        }
        & .carousel .control-arrow, 
        & .carousel.carousel-slider .control-arrow {
            opacity: ${(arrowOpacity ?? 40) / 100}
        }
        `;
    }
  }}

  ${({ arrowSettings }) => {
    if (!arrowSettings) return ``;
    const { arrowStyle, arrowColor = "#001C72" } = arrowSettings;
    const arrowColorHex = rgbaToHex(arrowColor);
    const svgArrowPrev = `<svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.835 18.13L10.065 19.9L0.165022 10L10.065 0.100006L11.835 1.87001L3.70502 10L11.835 18.13H11.835Z" fill="${arrowColorHex}"/></svg>`;
    const svgArrowNext = `<svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.164993 18.13L1.93499 19.9L11.835 10L1.93499 0.100006L0.164993 1.87001L8.29499 10L0.164993 18.13H0.164993Z" fill="${arrowColorHex}"/></svg>`;
    switch (arrowStyle) {
      case "arrow3":
        return `
        & .carousel .control-arrow {
            padding: 0;
            opacity: 1!important;
            background-color: transparent!important;
          }
        & .carousel .control-arrow.control-prev:before {
            border: none!important;
            width: 50px;
            height: 50px;
            background: url("/images/arrow-3.png") transparent no-repeat;
            rotate: 180deg!important;
            background-position: center center;
          }
          & .carousel .control-arrow.control-next:before {
            border: none!important;
            width: 50px;
            height: 50px;
            background: url("/images/arrow-3.png") transparent no-repeat;
            background-position: center center;
          }
        `;
      case "<LTS - GTS>":
        return `
          & .carousel .control-arrow {
            padding: 0;
          }
          & .carousel .control-arrow.control-prev:before {
            border: none!important;
            width: 12px;
            height: 20px;
            background: url(data:image/svg+xml,${encodeURIComponent(
              svgArrowPrev
            )}) transparent no-repeat;
          }
          & .carousel .control-arrow.control-next:before {
            border: none!important;
            width: 12px;
            height: 20px;
            background: url(data:image/svg+xml,${encodeURIComponent(
              svgArrowNext
            )}) transparent no-repeat;
          }
        `;
      case "default":
      default:
        break;
    }
  }}

  @media (max-width: 500px) {
    .carousel.carousel-slider .control-arrow {
      opacity: 1;
    }
  }
`;
