import { Builder, withChildren } from "@builder.io/react";
import loadable from "@loadable/component";

const OrderSummary = loadable(() => import("./OrderSummary"));
const OrderSummaryToggle = loadable(() => import("./OrderSummaryToggle"));

const OrderSummaryConfig = {
  name: "Order Summary",
  canHaveChildren: true,
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  inputs: []
};

Builder.registerComponent(OrderSummary, OrderSummaryConfig);

const OrderSummaryToggleConfig = {
  name: "Order Summary Toggle",
  canHaveChildren: true,
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  inputs: []
};

Builder.registerComponent(
  withChildren(OrderSummaryToggle),
  OrderSummaryToggleConfig
);
