import { Builder, withChildren } from "@builder.io/react";
import loadable from "@loadable/component";

const UpsellImage = loadable(() => import("./UpsellImage"));

const UpsellImageConfig = {
  name: "Upsell Image",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  canHaveChildren: true,
  // static: true,
  // noWrap: true,
  // hideFromInsertMenu: true,
  inputs: [
    {
      name: "orderImage",
      type: "boolean",
      defaultValue: false
    }
  ]
};

Builder.registerComponent(withChildren(UpsellImage), UpsellImageConfig);
