import { Builder, withChildren } from "@builder.io/react";
import ToggleContainer from "./ToggleContainer";

Builder.registerComponent(withChildren(ToggleContainer), {
  name: "Slides:ToggleContainer",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  inputs: [
    {
      name: "version",
      type: "string",
      enum: ["version1", "version2"],
      defaultValue: "version1"
    },
    {
      name: "designVersion",
      type: "string",
      enum: ["single", "multiple"],
      defaultValue: "single"
    },
    {
      name: "offerIndex",
      type: "number",
      defaultValue: 0,
      showIf: o => !o.get("submitCart")
    },
    {
      name: "heading",
      type: "string",
      defaultValue: ""
    },
    {
      name: "headingColor",
      type: "color",
      defaultValue: "#001C72"
    },
    {
      name: "headingActiveColor",
      type: "color",
      defaultValue: "#289901"
    },
    {
      name: "bgColor",
      type: "color",
      defaultValue: "#BC2E3E"
    },
    {
      name: "addedColor",
      type: "color",
      defaultValue: "#e26447"
    },
    {
      name: "hoverColor",
      type: "color",
      defaultValue: "#e26447"
    },
    {
      name: "showTickBox",
      type: "boolean",
      defaultValue: false,
      showIf: o => o.get("version") === "version1"
    },
    {
      name: "tickBoxColor",
      type: "color",
      defaultValue: "#e26447",
      showIf: o => o.get("showTickBox")
    },
    {
      name: "tickBoxColorActive",
      type: "color",
      defaultValue: "#e26447",
      showIf: o => o.get("showTickBox")
    },
    {
      name: "tickBoxPositionTop",
      type: "number",
      defaultValue: "10",
      showIf: o => o.get("showTickBox")
    },
    {
      name: "tickBoxPositionLeft",
      type: "number",
      defaultValue: "10",
      showIf: o => o.get("showTickBox")
    },
    {
      name: "tickBoxRadius",
      type: "string",
      defaultValue: "100%"
    },
    {
      name: "buttonText",
      type: "string",
      defaultValue: "ADD ITEM"
    },
    {
      name: "buttonTextSelected",
      type: "string",
      defaultValue: "REMOVE ITEM"
    },
    {
      name: "showNotification",
      type: "boolean",
      defaultValue: false
    },
    {
      name: "notificationTitle",
      type: "string",
      defaultValue: false,
      showIf: o => o.get("showNotification")
    },
    {
      name: "notificationType",
      type: "string",
      enum: ["success", "default", "warning", "info", "danger", "custom"],
      defaultValue: false,
      showIf: o => o.get("showNotification")
    }
  ]
});
