import { Builder, withChildren } from "@builder.io/react";
import loadable from "@loadable/component";

const DeclineButton = loadable(() => import("./DeclineButton"));

const DeclineButtonConfig = {
  name: "DeclineButton",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  canHaveChildren: true,
  inputs: [
    {
      name: "backgroundDesign",
      type: "string",
      enum: ["no-design", "design1"],
      defaultValue: "design1"
    },
    {
      name: "loadingBgColor",
      type: "color",
      defaultValue: "#FFF",
      showIf: o => o.get("backgroundDesign") === "no-design"
    },
    {
      name: "directToCheckout",
      type: "boolean",
      defaultValue: false,
      showIf: o => o.get("backgroundDesign") === "no-design"
    },
    {
      name: "triggerOnTimeout",
      type: "boolean",
      defaultValue: false,
      advanced: true
    },
    {
      name: "borderRadius",
      type: "number",
      advanced: true
    },
    {
      name: "minHeight",
      type: "number",
      advanced: true
    },
    {
      name: "isPreviewLoading",
      type: "boolean",
      defaultValue: false,
      advanced: true
    }
  ]
};

Builder.registerComponent(withChildren(DeclineButton), DeclineButtonConfig);
