import React from "react";
import RootLayout from "./src/layouts/RootLayout";
import PageLayout from "./src/layouts/PageLayout";

export const wrapRootElement = ({ element, props }) => {
  return <RootLayout {...props}>{element}</RootLayout>;
};

export const wrapPageElement = ({ element, props }) => {
  return <PageLayout {...props}>{element}</PageLayout>;
};

const addTPScript = () => {
  const script = document.createElement("script");
  script.src =
    "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    setTimeout(() => {
      addTPScript();
    }, 1000);
  };
};
