import { Builder, withChildren } from "@builder.io/react";
import loadable from "@loadable/component";

const UpsellText = loadable(() => import("./UpsellText"));

const defaultText = `Use this template tags: [title] [display-title] [discounted-price] 
[price] [savings] [discount-rate] [product-name] [currency-symbol] [tax-label] [tax-amount] 
[customer-firstname] [charge-amount] [discounted-price-whole] [discounted-price-decimal] 
[discounted-price-per-tub]`;

const UpsellTextConfig = {
  name: "Upsell Text",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  canHaveChildren: true,
  noWrap: true,
  // static: true,
  // hideFromInsertMenu: true,
  inputs: [
    {
      name: "items",
      type: "list",
      defaultValue: [{ text: defaultText }],
      subFields: [
        {
          name: "text",
          type: "richText",
          defaultValue: defaultText,
          helperText: defaultText
        }
      ]
    },
    {
      name: "defaultIndex",
      type: "number",
      defaultValue: 0,
      advanced: true
    },
    {
      name: "forceDefaultIndex",
      type: "boolean",
      defaultValue: false,
      advanced: true
    },
    {
      name: "removeParagraphMargins",
      type: "boolean",
      defaultValue: false,
      advanced: true
    }
  ]
};

Builder.registerComponent(withChildren(UpsellText), UpsellTextConfig);
