import React, { useState, useEffect } from "react";

function getScrollPositionY() {
  return window.pageYOffset !== undefined
    ? window.pageYOffset
    : window.scrollTop;
}

function LazyLoadContainer({ showPositionY, children, builderBlock }) {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (getScrollPositionY() > (showPositionY ?? 0)) {
        setLoad(true);
      }
    });
  }, [setLoad, builderBlock, showPositionY]);

  return load ? children : <div style={{ paddingTop: 100 }} />;
}

export default LazyLoadContainer;
