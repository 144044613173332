import { Builder, withChildren } from "@builder.io/react";
import LazyLoadContainer from "./LazyLoadContainer";

Builder.registerComponent(withChildren(LazyLoadContainer), {
  name: "Lazy Load Container",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  inputs: [
    {
      name: "showPositionY",
      type: "number",
      defaultValue: 500
    }
  ]
});
