import { Builder, withChildren } from "@builder.io/react";
import loadable from "@loadable/component";

const OfferContainer = loadable(() => import("./OfferContainer"));

const OfferContainerConfig = {
  name: "Offer Container",
  canHaveChildren: true,
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  inputs: [
    {
      name: "designVersion",
      type: "string",
      enum: ["version1"],
      defaultValue: "version1"
    },
    {
      name: "offerIndex",
      type: "number",
      defaultValue: 0
    }
  ]
};

Builder.registerComponent(withChildren(OfferContainer), OfferContainerConfig);
