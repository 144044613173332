import React from "react";
import * as S from "./CarouselContainer.styles";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.css";

const CarouselContainer = props => {
  const { settings, children } = props;

  const thumbsPosition = settings?.thumbsPosition || "bottom";
  const thumbWidth = parseInt(settings?.thumbWidth ?? 80);

  return (
    <S.Container
      arrowSettings={settings?.arrowSettings}
      dotSettings={settings?.dotSettings}
      addItemGap={settings?.addItemGap}
      itemGapSize={settings?.itemGapSize}
      className="carousel-container"
    >
      <Carousel
        showStatus={settings?.showStatus ?? false}
        thumbWidth={thumbWidth}
        thumbsPosition={thumbsPosition}
        swipeScrollTolerance={settings?.swipeScrollTolerance ?? 5}
        autoPlay={settings?.autoPlay ?? false}
        centerMode={settings?.centerMode ?? false}
        centerSlidePercentage={settings?.centerSlidePercentage ?? 80}
        dynamicHeight={settings?.dynamicHeight ?? false}
        emulateTouch={settings?.emulateTouch ?? false}
        infiniteLoop={settings?.infiniteLoop ?? false}
        interval={settings?.interval ?? 3000}
        preventMovementUntilSwipeScrollTolerance={
          settings?.preventMovementUntilSwipeScrollTolerance ?? true
        }
        stopOnHover={settings?.stopOnHover ?? false}
        swipeable={settings?.swipeable ?? true}
        useKeyboardArrows={settings?.useKeyboardArrows ?? false}
        showArrows={settings?.showArrows ?? true}
        showIndicators={settings?.showIndicators ?? true}
        showThumbs={settings?.showThumbs ?? true}
        ariaLabel={settings?.ariaLabel ?? ""}
        axis={settings?.axis ?? "horizontal"}
        transitionTime={settings?.transitionTime ?? 500}
      >
        {children}
      </Carousel>
    </S.Container>
  );
};

export default CarouselContainer;
