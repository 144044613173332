import { builder } from "@builder.io/react";

// a set of widgets you can use in the editor, optional.
import "@builder.io/widgets";

/**
 * Import all custom components so you can use in the builder.io editor
 * https://www.builder.io/c/docs/custom-react-components
 */
// components
import "./components/AcceptButton/AcceptButton.builder";
import "./components/DeclineButton/DeclineButton.builder";
import "./components/Timer/Timer.builder";
import "./components/TrustpilotPlugin/TrustpilotPlugin.builder";
import "./components/UpsellImage/UpsellImage.builder";
import "./components/UpsellText/UpsellText.builder";
import "./components/LazyLoadContainer/LazyLoadContainer.builder";
import "./components/StampedPlugin/StampedPlugin.builder";
import "./components/ImageCarousel/ImageCarousel.builder";
import "./components/AddToCartButton/AddToCartButton.builder";
import "./components/OrderContains/OrderContains.builder";
import "./components/ToggleContainer/ToggleContainer.builder";
import "./components/OfferContainer/OfferContainer.builder";
import "./components/OkendoReviewsWidget/OkendoReviewsWidget.builder";
import "./components/CarouselContainer/CarouselContainer.builder";
import "./components/OrderSummary/OrderSummary.builder";
import "./components/Accordion/Accordion.builder";

builder.init(process.env.GATSBY_BUILDER_API_KEY);
