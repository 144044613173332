import { Builder, withChildren } from "@builder.io/react";
import loadable from "@loadable/component";

const OrderContains = loadable(() => import("./OrderContains"));

const OrderContainsConfig = {
  name: "Order Contains",
  canHaveChildren: true,
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  inputs: [
    {
      name: "designVersion",
      type: "string",
      enum: ["version1", "version2", "version3", "version4"],
      defaultValue: "version1"
    },
    {
      name: "noSummary",
      type: "boolean",
      defaultValue: false
    },
    {
      name: "showCurrentIndexOnly",
      type: "boolean",
      defaultValue: false
    },
    {
      name: "addUpgrades",
      type: "boolean",
      defaultValue: false
    },
    {
      name: "subTitleText",
      type: "string",
      defaultValue: "One-time"
    },
    {
      name: "language",
      type: "string",
      enum: ["default", "spanish"],
      default: "default",
      advanced: true
    }
  ]
};

Builder.registerComponent(withChildren(OrderContains), OrderContainsConfig);
