import { Builder, withChildren } from "@builder.io/react";
import loadable from "@loadable/component";

const AcceptButton = loadable(() => import("./AcceptButton"));

const AcceptButtonConfig = {
  name: "AcceptButton",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  canHaveChildren: true,
  // static: true,
  // noWrap: true,
  // hideFromInsertMenu: true,
  inputs: [
    {
      name: "version",
      type: "string",
      enum: ["no-design", "version1", "add-sub"],
      defaultValue: "version1"
    },
    {
      name: "backgroundDesign",
      type: "string",
      enum: ["design1", "design2"],
      defaultValue: "design1"
    },
    {
      name: "loadingBgColor",
      type: "color",
      defaultValue: "#FFF",
      showIf: o => o.get("version") === "no-design"
    },
    {
      name: "showConfirmation",
      type: "boolean",
      defaultValue: false
    },
    {
      name: "confirmationVersion",
      type: "string",
      enum: ["version1", "version2"],
      defaultValue: "version1",
      showIf: o => o.get("version") !== "add-sub"
    },
    {
      name: "noTaxComputation",
      type: "boolean",
      defaultValue: false,
      showIf: o => o.get("showConfirmation")
    },
    {
      name: "subProduct",
      type: "object",
      subFields: [
        {
          name: "variantId",
          type: "string"
        },
        {
          name: "discountCode",
          type: "string"
        },
        {
          name: "quantity",
          type: "number",
          defaultValue: 1
        },
        {
          name: "discountPrice",
          type: "number"
        }
      ],
      showIf: o => o.get("version") === "add-sub"
    },
    {
      name: "popupTopImage",
      type: "file",
      allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
      required: false,
      showIf: o => o.get("version") === "add-sub"
    },
    {
      name: "borderRadius",
      type: "number",
      advanced: true
    },
    {
      name: "minHeight",
      type: "number",
      advanced: true
    },
    {
      name: "isPreviewLoading",
      type: "boolean",
      defaultValue: false,
      advanced: true
    }
  ]
};

Builder.registerComponent(withChildren(AcceptButton), AcceptButtonConfig);
