import { Builder } from "@builder.io/react";
import loadable from "@loadable/component";

const StampedPlugin = loadable(() => import("./StampedPlugin"));

Builder.registerComponent(StampedPlugin, {
  name: "Stamped Plugin",
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  inputs: [
    {
      name: "publicAPIKey",
      type: "string",
      defaultValue: ""
    },
    {
      name: "storeUrl",
      type: "string",
      defaultValue: ""
    },
    {
      name: "productId",
      type: "string",
      defaultValue: ""
    },
    {
      name: "productName",
      type: "string",
      defaultValue: ""
    },
    {
      name: "productUrl",
      type: "string",
      defaultValue: ""
    },
    {
      name: "productImage",
      type: "string",
      defaultValue: ""
    },
    {
      name: "productDescription",
      type: "string",
      defaultValue: ""
    },
    {
      name: "productSku",
      type: "string",
      defaultValue: ""
    }
  ]
});
