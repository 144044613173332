import React from "react";
import loadable from "@loadable/component";

const ToggleContainerV1 = loadable(() => import("./ToggleContainerV1"));
const ToggleContainerV2 = loadable(() => import("./ToggleContainerV2"));

const ToggleContainer = props => {
  const { version } = props;
  switch (version) {
    case "version2":
      return <ToggleContainerV2 {...props} />;
    default:
    case "version1":
      return <ToggleContainerV1 {...props} />;
  }
};

export default ToggleContainer;
