import React from "react";
import * as S from "./ImageCarousel.styles";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.css";

const ImageCarousel = props => {
  const { images, settings, children, attributes } = props;

  const thumbsPosition = settings?.thumbsPosition || "bottom";
  const thumbWidth = parseInt(settings?.thumbWidth ?? 80);

  const renderThumbs = () => {
    return images.map((image, index) => {
      const imageAlt = image.alt ? image.alt : `Carousel Thumb ${index}`;
      const videoClass = image?.video ? `is-video thumbnail` : ``;

      return (
        <div key={index} className={`${videoClass}`}>
          <img
            src={`${image.image}?width=500`}
            alt={imageAlt}
            width={"500"}
            height="100%"
          />
        </div>
      );
    });
  };

  return (
    <S.Container
      {...attributes}
      arrowSettings={settings?.arrowSettings}
      dotSettings={settings?.dotSettings}
    >
      <Carousel
        showStatus={settings?.showStatus ?? false}
        thumbWidth={thumbWidth}
        thumbsPosition={thumbsPosition}
        swipeScrollTolerance={settings?.swipeScrollTolerance ?? 5}
        autoPlay={settings?.autoPlay ?? false}
        centerMode={settings?.centerMode ?? false}
        dynamicHeight={settings?.dynamicHeight ?? false}
        emulateTouch={settings?.emulateTouch ?? false}
        infiniteLoop={settings?.infiniteLoop ?? false}
        interval={settings?.interval ?? 3000}
        preventMovementUntilSwipeScrollTolerance={
          settings?.preventMovementUntilSwipeScrollTolerance ?? true
        }
        stopOnHover={settings?.stopOnHover ?? false}
        swipeable={settings?.swipeable ?? true}
        useKeyboardArrows={settings?.useKeyboardArrows ?? false}
        selectedItem={settings?.selectedItem ?? 0}
        showArrows={settings?.showArrows ?? true}
        showIndicators={settings?.showIndicators ?? true}
        showThumbs={settings?.showThumbs ?? true}
        ariaLabel={settings?.ariaLabel ?? ""}
        axis={settings?.axis ?? "horizontal"}
        transitionTime={settings?.transitionTime ?? 500}
        renderThumbs={renderThumbs}
      >
        {settings?.showImgArray
          ? images.map((image, index) => {
              const imageAlt = image.alt ? image.alt : `Carousel ${index}`;

              return (
                <React.Fragment key={index}>
                  <div key={index} role="button" aria-hidden="true">
                    <img
                      src={`${image.image}?width=500`}
                      alt={imageAlt}
                      width={390}
                      height={390}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                </React.Fragment>
              );
            })
          : children}
      </Carousel>
    </S.Container>
  );
};

export default ImageCarousel;
