import { Builder } from "@builder.io/react";
import OkendoReviewsWidget from "./OkendoReviewsWidget";

Builder.registerComponent(OkendoReviewsWidget, {
  name: "Okendo Reviews Widget",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  inputs: [
    {
      name: "subscriberId",
      type: "string",
      defaultValue: "a6ff1c99-dd8a-4420-be09-f24d9bd7dee3"
    },
    {
      name: "productId",
      type: "string",
      defaultValue: "6645452079159"
    },
    {
      name: "maxWidth",
      type: "number",
      defaultValue: 1150
    }
  ]
});
