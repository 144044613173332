import React from "react";
import loadable from "@loadable/component";

const Helmet = loadable(() => import("react-helmet"));
const GlobalStyle = loadable(() => import("../theme/globalStyle"));

export default function RootLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="theme-color" content="#FFFFFF" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      </Helmet>
      <GlobalStyle />
      {props.children}
    </React.Fragment>
  );
}
