import { Builder, withChildren } from "@builder.io/react";
import loadable from "@loadable/component";

const AddToCartButton = loadable(() => import("./AddToCartButton"));

const AddToCartButtonConfig = {
  name: "AddToCartButton",
  image:
    "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F62750e75f4b643bdbc747c882e46e77f?width=24",
  canHaveChildren: true,
  inputs: [
    {
      name: "offerIndex",
      type: "number",
      defaultValue: 0,
      showIf: o => !o.get("submitCart")
    },
    {
      name: "submitCart",
      type: "boolean",
      defaultValue: false
    },
    {
      name: "loadingBgColor",
      type: "color",
      defaultValue: "#FFF",
      showIf: o => o.get("submitCart")
    },
    {
      name: "showConfirmation",
      type: "boolean",
      defaultValue: false
    },
    {
      name: "showNotification",
      type: "boolean",
      defaultValue: false
    },
    {
      name: "notificationTitle",
      type: "string",
      defaultValue: false,
      showIf: o => o.get("showNotification")
    },
    {
      name: "notificationType",
      type: "string",
      enum: ["success", "default", "warning", "info", "danger", "custom"],
      defaultValue: false,
      showIf: o => o.get("showNotification")
    },
    {
      name: "isDisabled",
      type: "boolean",
      defaultValue: false
    }
  ]
};

Builder.registerComponent(withChildren(AddToCartButton), AddToCartButtonConfig);
